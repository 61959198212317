import { render, staticRenderFns } from "./apps.vue?vue&type=template&id=78c78852&scoped=true&"
import script from "./apps.vue?vue&type=script&lang=js&"
export * from "./apps.vue?vue&type=script&lang=js&"
import style0 from "./apps.vue?vue&type=style&index=0&id=78c78852&prod&scoped=true&lang=less&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "78c78852",
  null
  
)

export default component.exports